import { FmdGoodOutlined, MoreHorizOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";

export const RentalCard = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      p={2}
      borderRadius={2.5}
      border={"1px solid red"}
      borderColor="divider"
      display="flex"
      flexDirection="column"
      gap={1.5}
    >
      <Box width="100%" height={180} borderRadius={2.5} overflow="hidden">
        <img
          width="100%"
          height="100%"
          alt="rental-property"
          src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          style={{ objectFit: "cover" }}
        />
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <Avatar
          src="https://images.unsplash.com/photo-1466112928291-0903b80a9466?q=80&w=2073&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          sx={{ width: 30, height: 30 }}
        />
        <Typography
          fontSize={16}
          color="text.primary"
          lineHeight="150%"
          flex={1}
        >
          Annette Black
        </Typography>
        <IconButton
          color="inherit"
          id="demo-positioned-button"
          aria-controls={open ? "demo-positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreHorizOutlined sx={{ color: "#12171E" }} />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        <FmdGoodOutlined sx={{ width: 18, height: 18, color: "#12171E" }} />
        <Typography
          fontSize={12}
          lineHeight="160%"
          color="text.secondary"
          flex={1}
        >
          Boston, MA
        </Typography>
        <Chip label="Paid" color="success" size="small" variant="outlined" />
      </Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>View Listing</MenuItem>
        <MenuItem onClick={handleClose}>Send Reminder</MenuItem>
        <MenuItem onClick={handleClose}>Message</MenuItem>
      </Menu>
    </Box>
  );
};
