import { useAppTheme } from "../core/useAppTheme";
import { DashboardLayout } from "../components";
import Button from "@mui/material/Button";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  Drawer,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  AttachmentOutlined,
  ChevronLeft,
  KeyboardArrowDownOutlined,
  LocalPhoneOutlined,
  MenuOutlined,
  MoreVertOutlined,
  SearchOutlined,
  SendOutlined,
  SentimentSatisfiedOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { FC, useState } from "react";

const MessagePage = () => {
  const { tablet } = useAppTheme();
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  return (
    <DashboardLayout>
      <Box
        border="1px solid red"
        display="flex"
        borderColor="divider"
        width="100%"
        height={tablet ? "calc(100vh - 106px)" : "calc(100vh - 146px)"}
        borderRadius={3}
      >
        <ContactMenu setState={setOpenDrawer} state={openDrawer} />
        <Box flex={1} height="100%" display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" gap={1} p={2}>
            {tablet ? (
              <IconButton
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <MenuOutlined />
              </IconButton>
            ) : (
              <></>
            )}
            <ListItem dense disableGutters>
              <ListItemAvatar>
                <Badge
                  color={"secondary"}
                  variant="dot"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  overlap="circular"
                >
                  <Avatar
                    alt={" Michell Flintoff"}
                    src={
                      "https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    sx={{ width: 40, height: 40 }}
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    fontSize={18}
                    fontWeight={600}
                    color="text.secondary"
                  >
                    Michell Flintoff
                  </Typography>
                }
                secondary={
                  <Typography variant="body2" color="text.primary">
                    away
                  </Typography>
                }
              />
            </ListItem>
            <Stack direction={"row"}>
              <IconButton aria-label="phone">
                <LocalPhoneOutlined />
              </IconButton>
              <IconButton aria-label="video">
                <VideocamOutlined />
              </IconButton>
              <IconButton>
                <MoreVertOutlined />
              </IconButton>
            </Stack>
          </Box>
          <Divider />
          <Box
            sx={{
              overflow: "auto",
              width: "100%",
              padding: 3,
              flex: 1,
            }}
          >
            <Box>
              <Box display="flex">
                <ListItemAvatar>
                  <Avatar
                    alt={" Michell Flintoff"}
                    src={
                      "https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    sx={{ width: 40, height: 40, mt: 1 }}
                  />
                </ListItemAvatar>
                <Box>
                  <Typography variant="body2" color="grey.400" mb={1}>
                    7 hour ago
                  </Typography>
                  <Box
                    mb={2}
                    sx={{
                      p: 1,
                      px: 1.5,
                      borderRadius: 1.5,
                      backgroundColor: "grey.50",
                      mr: "auto",
                      maxWidth: "320px",
                    }}
                  >
                    Ur vej de ah eti zossu irawosit amihezsuz noc san.
                  </Box>
                </Box>
              </Box>
              <Box
                mb={1}
                display="flex"
                alignItems="flex-end"
                flexDirection="row-reverse"
              >
                <Box
                  alignItems="flex-end"
                  display="flex"
                  flexDirection={"column"}
                >
                  <Typography variant="body2" color="grey.400" mb={1}>
                    7 hour ago
                  </Typography>
                  <Box
                    mb={1}
                    sx={{
                      p: 1,
                      px: 1.5,
                      borderRadius: 1.5,
                      backgroundColor: "grey.50",
                      ml: "auto",
                      maxWidth: "320px",
                    }}
                  >
                    Ur vej de ah eti zossu irawosit amihezsuz noc san.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider />
          <Box p={2}>
            <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <IconButton>
                <SentimentSatisfiedOutlined />
              </IconButton>
              <InputBase
                id="msg-sent"
                fullWidth
                placeholder="Type a Message"
                size="small"
                type="text"
                inputProps={{ "aria-label": "Type a Message" }}
              />
              <IconButton>
                <SendOutlined />
              </IconButton>
              <IconButton>
                <AttachmentOutlined />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

const ContactMenu: FC<{ state: boolean; setState: Function }> = ({
  state,
  setState,
}) => {
  const { tablet } = useAppTheme();

  const childComponets = (
    <>
      <Box p={3} pb={1}>
        <TextField
          placeholder="Search contacts"
          variant="outlined"
          sx={{
            "& input": {
              padding: "8.5px 14px",
            },
            "& .MuiInputAdornment-root": {
              margin: "8px",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Box>
      <Dropdown />
      <Box
        mt={1}
        overflow="auto"
        height={tablet ? "calc(100vh - 106px)" : "calc(100vh - 260px)"}
      >
        {[1, 2, 3, 4, 5, 6, 7].map((e, key) => {
          return (
            <ListItemButton
              key={key}
              sx={{
                mb: 0.5,
                py: 2,
                pb: 1.75,
                px: 3,
                alignItems: "start",
              }}
              selected={key === 1}
            >
              <ListItemAvatar>
                <Badge
                  color={"secondary"}
                  variant="dot"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  overlap="circular"
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={
                      "https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    }
                    sx={{ width: 42, height: 42 }}
                  />
                </Badge>
              </ListItemAvatar>
              <Box display="flex" flexDirection="column" gap={0.5} width="100%">
                <Box display="flex" width="100%">
                  <Typography
                    flex={1}
                    variant="body2"
                    color="text.primary"
                    fontWeight={600}
                  >
                    Michell Flintoff
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    15 minutes
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  You: Yesterdy was great...
                </Typography>
              </Box>
            </ListItemButton>
          );
        })}
      </Box>
    </>
  );

  return tablet ? (
    <Drawer
      open={state}
      onClose={() => {
        setState(false);
      }}
      sx={{
        "& .MuiDrawer-paper": { boxSizing: "border-box", width: 320, bgcolor: '#fff' },
      }}
    >
      {childComponets}
    </Drawer>
  ) : (
    <Box
      width={320}
      borderRight="1px solid red"
      borderColor="divider"
      height="100%"
    >
      {childComponets}
    </Box>
  );
};

const Dropdown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box px={3}>
      <Button
        color="inherit"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownOutlined />}
        sx={{ py: 0.5, px: 1, borderRadius: 1 }}
      >
        Recent Chats
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>Sort By Time</MenuItem>
        <MenuItem>Sort By Unread</MenuItem>
        <MenuItem>Mark as all Read</MenuItem>
      </Menu>
    </Box>
  );
};

export default MessagePage;
