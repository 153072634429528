import React, { useState } from "react";
import { DashboardLayout } from "../components";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useAppTheme } from "../core/useAppTheme";
import { AppTable } from "../components/card/AppTable";
import { BugReportOutlined, MoreVert } from "@mui/icons-material";

const IssuesPage = () => {
  const { mobile } = useAppTheme();
  const [value, setValue] = useState(0);

  return (
    <DashboardLayout
      title="Good morning"
      action={
        <Button
          variant="contained"
          startIcon={<BugReportOutlined/>}
          sx={{
            width: mobile ? "100%" : "fit-content",
          }}
        >
          Create Issue
        </Button>
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          sx={{ mt: 2 }}
          onChange={(e, value) => {
            setValue(value);
          }}
        >
          <Tab color="seconadary" label="All" value={0} />
          <Tab color="seconadary" label="Open" value={1} />
          <Tab color="seconadary" label="Resolve" value={2} />
          <Tab color="seconadary" label="Report" value={3} />
        </Tabs>
      </Box>
      <Box pt={5} pb={2} display="flex" gap={1}>
        <TextField
          variant="outlined"
          placeholder="Select"
          defaultValue={"all"}
          onChange={(e) => {}}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "5px",
            },
            "& .MuiSelect-select": {
              pl: 2,
            },
          }}
          select
        >
          <MenuItem value={"all"}>All Rentals</MenuItem>
          <MenuItem value={"susan"}>Susan</MenuItem>
        </TextField>
      </Box>
      <AppTable
        colSpan={[2, 2, 1, 1, 1, 1, 1]}
        headings={[
          "Issue ID",
          "Rental Name",
          "Property Name",
          "Purpose",
          "Creater",
          "Date",
          "Status",
          "Action",
        ]}
        row={[
          [
            "#459323409",
            "Susan",
            "3 Industrial - A l Julie Russell",
            "This house has sewage problem",
            "Rental",
            "10/1/2021",
            <Chip label="Resolve" color="success" variant="outlined" />,
            <ActionButton />,
          ],
          [
            "#459323409",
            "Susan",
            "3 Industrial - A l Julie Russell",
            "This house has sewage problem",
            "Landlord",
            "10/1/2021",
            <Chip label="Open" color="warning" variant="outlined" />,
            <ActionButton />,
          ],
          [
            "#459323409",
            "Susan",
            "3 Industrial - A l Julie Russell",
            "This house has sewage problem",
            "Rental",
            "10/1/2021",
            <Chip label="Report" color="error" variant="outlined" />,
            <ActionButton />,
          ],
        ]}
      />
    </DashboardLayout>
  );
};

const ActionButton = () => {
  // states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* menu */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            View Issue
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            Issue Resolved
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            Report Issue
          </Typography>
        </MenuItem>
      </Menu>

      {/* button */}
      <IconButton key={0} color="inherit" onClick={handleClick}>
        <MoreVert />
      </IconButton>
    </>
  );
};

export default IssuesPage;
