import * as yup from "yup";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AuthLayout,
  FormCheckBox,
  FormPasswordField,
  FormTextField,
} from "../components";
import { authBG } from "../assets/images";
import { appleLogo, googleLogo } from "../images";
import { Link } from "react-router-dom";
import { useAppTheme } from "../core/useAppTheme";

const LoginPage = () => {
  const { palette, tablet, navigate } = useAppTheme();

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Incorrect Email Address")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be 6 to 24 letter long")
      .max(24, "Password must be 6 to 24 letter long"),
    agree: yup
      .boolean()
      .test(
        "is-true",
        "Please agree to our Terms & Privacy",
        (value) => value === true
      ),
  });

  // react hook form
  const FormMethod = useForm({
    defaultValues: {
      email: "",
      password: "",
      agree: false,
    },
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit } = FormMethod;

  // post funtion
  const submitForm = async (data: any) => {
    console.log(data);
    navigate('/dashboard');
  };

  return (
    <AuthLayout image={authBG}>
      <Container maxWidth="sm" sx={{ pt: tablet ? 15 : 10, pb: 8 }}>
        <Typography lineHeight="130%" fontWeight={700} fontSize={28}>
          Log In
        </Typography>
        <FormProvider {...FormMethod}>
          <Box
            onSubmit={handleSubmit(submitForm)}
            component="form"
            display="flex"
            gap={3}
            flexDirection="column"
            pt={3}
          >
            <FormTextField
              name="email"
              label="Email Address"
              placeholder="Enter your email"
            />
            <FormPasswordField
              name="password"
              label={
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    Password
                    <Link
                      to={"/forget-password"}
                      style={{ fontWeight: 600, color: palette.text.secondary }}
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                </>
              }
              placeholder="Enter your password"
            />
            <FormCheckBox name="agree" label="I Agree to the Terms & Privacy" />
            <Button
              variant="contained"
              size="large"
              type="submit"
              disableElevation
              fullWidth
              sx={{
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "150%",
                py: tablet ? 1.25 : 2,
              }}
            >
              Log In
            </Button>
            <Box width="100%" display="flex" alignItems="center">
              <Divider sx={{ flex: 1 }} />
              <Typography
                color="text.secondary"
                fontSize={14}
                fontWeight={500}
                lineHeight="150%"
                px={3}
              >
                or
              </Typography>
              <Divider sx={{ flex: 1 }} />
            </Box>
            <Box display="flex" gap={3}>
              <Button
                startIcon={
                  <img src={googleLogo} height={20} alt="google logo" />
                }
                sx={{ borderRadius: 2.5, borderColor: "divider", py: 1.25 }}
                variant="outlined"
                color="inherit"
                fullWidth
              >
                Google
              </Button>
              <Button
                startIcon={<img src={appleLogo} height={20} alt="apple logo" />}
                sx={{ borderRadius: 2.5, borderColor: "divider", py: 1.25 }}
                variant="outlined"
                color="inherit"
                fullWidth
              >
                Apple
              </Button>
            </Box>
            <Typography
              fontSize={14}
              color="text.secondary"
              lineHeight="150%"
              pt={1}
              textAlign={tablet ? "center" : "left"}
            >
              Have an account?{" "}
              <Link
                to={"/signup"}
                style={{
                  fontWeight: 600,
                  color: palette.text.secondary,
                }}
              >
                Sign In
              </Link>
            </Typography>
          </Box>
        </FormProvider>
      </Container>
    </AuthLayout>
  );
};

export default LoginPage;
