import {
  TableCellProps,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TablePaginationBaseProps,
  TablePaginationTypeMap,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { ReactNode, FC } from "react";

interface DataModel {
  headings: ReactNode[];
  row: ReactNode[][];
  props?: TableCellProps[];
  rowSpan?: number[];
  colSpan?: number[];
  pagination?: boolean;
}

export const AppTable: FC<DataModel> = ({
  headings,
  row,
  props = [],
  rowSpan = [],
  colSpan = [],
  pagination,
}) => {
  return (
    <TableContainer>
      <Table
        sx={{
          "& td": {
            height: 71,
          },
        }}
      >
        <TableHead
          sx={{
            borderTop: "1px solid red",
            borderColor: "divider",
          }}
        >
          <TableRow>
            {headings.map((e, key) => {
              return (
                <TableCell
                  {...props[key]}
                  rowSpan={rowSpan[key]}
                  colSpan={colSpan[key]}
                  key={key}
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "text.secondary",
                    whiteSpace: "nowrap",
                    borderColor: "divider",
                  }}
                >
                  {e}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {row.map((row, key) => (
            <TableRow
              key={key}
              sx={{
                transitionDuration: "500ms",
                borderRadius: "10px",
                cursor: "pointer",
                ":hover": {
                  boxShadow: "0px 4px 20px rgba(70, 87, 147, 0.10)",
                },
              }}
            >
              {row.map((e, key) => {
                return (
                  <TableCell
                    {...props[key]}
                    rowSpan={rowSpan[key]}
                    colSpan={colSpan[key]}
                    key={key}
                    sx={{
                      fontSize: 14,
                      color: "text.secondary",
                      borderColor: "divider",
                    }}
                  >
                    {e}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination ? (
        <TablePagination
          component="div"
          count={100}
          page={2}
          onPageChange={() => {}}
          rowsPerPage={5}
        />
      ) : (
        <></>
      )}
    </TableContainer>
  );
};
