import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Zoom } from "swiper/modules";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { FC, useState } from "react";
import { CloseOutlined } from "@mui/icons-material";

const PropertyDetailImages: FC<{ data: string[] }> = ({ data }) => {
  const filterData = [data[1], data[2], data[3]];
  const [openModel, setOpenModel] = useState(false);

  return (
    <Box display="flex" width="100%" gap={1.5} height={"680px"}>
      <Box
        bgcolor="background.paper"
        flex={3}
        borderRadius={"12px 0px 0px 12px"}
        overflow="hidden"
        sx={{ cursor: "pointer" }}
      >
        <img
          alt="property"
          src={data[0]}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </Box>
      <Box display="flex" flexDirection="column" flex={1} gap={1.5}>
        {filterData.map((e, key) => {
          return (
            <Box
              key={key}
              bgcolor="background.paper"
              flex={1}
              borderRadius={"0px 12px 12px 0px"}
              position="relative"
              overflow="hidden"
              sx={{ cursor: "pointer" }}
            >
              <img
                alt={`property-${key}`}
                src={e}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              {filterData.length - 1 === key ? (
                <Box
                  position="absolute"
                  top={0}
                  left={0}
                  px={3}
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  onClick={() => {
                    setOpenModel(true);
                  }}
                  sx={{
                    bgcolor: "rgba(230, 230, 230, 0)",
                    transitionDuration: "0.2s",
                    ":hover": {
                      bgcolor: "rgba(230, 230, 230, 0.25)",
                    },
                  }}
                >
                  <Box
                    py={1.25}
                    px={2.5}
                    borderRadius={1.5}
                    bgcolor="white"
                    sx={{
                      boxShadow: "0px 10px 40px 0px rgba(24, 26, 32, 0.05)",
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="text.primary"
                      fontWeight={700}
                    >
                      See All {data.length} Photos
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Box>
          );
        })}
      </Box>
      <Dialog
        fullScreen
        open={openModel}
        onClose={() => {
          setOpenModel(false);
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "transparent",
            backdropFilter: "blur(3px)",
          },
        }}
      >
        <Swiper
          zoom={true}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[Zoom, Navigation, Pagination]}
          spaceBetween={24}
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {data.map((e, key) => {
            return (
              <SwiperSlide key={key}>
                <div className="swiper-zoom-container">
                  <img src={e} alt={`property-${key}`} />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <Box
          position="absolute"
          gap={2}
          display="flex"
          top={0}
          right={0}
          p={3}
          zIndex={5}
        >
          <IconButton
            sx={{
              color: "#fff",
              background: `rgba(230, 230, 230, 0.80) !important`,
            }}
            onClick={() => {
              setOpenModel(false);
            }}
          >
            <CloseOutlined />
          </IconButton>
        </Box>
      </Dialog>
    </Box>
  );
};

export default PropertyDetailImages;
