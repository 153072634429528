"use client";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  SxProps,
  Theme,
} from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
export interface CheckBoxDataModel {
  name: string;
  label: string;
  disable?: boolean;
  helpText?: string;
  sx?: SxProps<Theme>;
}

export const FormCheckBox: FC<CheckBoxDataModel> = ({
  name,
  label,
  sx,
  disable,
  helpText,
}) => {
  const {
    formState: { errors },
    control,
    getValues,
  } = useFormContext();

  const isError = errors?.[name]?.message ? true : false;
  const errorMessage = errors?.[name]?.message
    ? errors?.[name]?.message
    : helpText;

  return (
    <Box>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControlLabel
            label={label}
            sx={sx}
            disabled={disable}
            control={
              <Checkbox {...field} checked={field.value} color="secondary" />
            }
          />
        )}
      />
      <FormHelperText error={isError}>
        <>{errorMessage}</>
      </FormHelperText>
    </Box>
  );
};
