import { ThemeOptions, colors } from "@mui/material"

export const appColor = {
    pri: {
        l: 'hsl(88, 100%, 52%)',
        m: 'hsl(88, 100%, 42%)',
        d: 'hsl(88, 100%, 32%)',
    },
    sec: {
        l: 'hsl(217, 100%, 66%)',
        m: 'hsl(217, 100%, 56%)',
        d: 'hsl(217, 100%, 46%)',
        t: '#F1F6FF'
    },
    text: {
        pri: '#020615',
        sec: '#32393C'
    },
    grey: {
        0: 'hsl(0, 0%, 100%)',
        2: 'hsl(0, 0%, 98%)',
        4: 'hsl(0, 0%, 96%)',
        5: 'hsl(0, 0%, 95%)',
        6: 'hsl(0, 0%, 94%)',
        8: 'hsl(0, 0%, 92%)',
        10: 'hsl(0, 0%, 90%)',
        20: 'hsl(0, 0%, 80%)',
        30: 'hsl(0, 0%, 70%)',
        40: 'hsl(0, 0%, 60%)',
        50: 'hsl(0, 0%, 50%)',
        60: 'hsl(0, 0%, 40%)',
        70: 'hsl(0, 0%, 30%)',
        80: 'hsl(0, 0%, 20%)',
        90: 'hsl(0, 0%, 10%)',
        100: 'hsl(0, 0%, 0%)',
    },
    other: {
        whitetrans: 'rgba(230, 230, 230, 0.80)',
        darkbg: '#101214',
        bg: '#FFFFFF',
        paper: '#F5F6F7',
        divider: '#EBEDF0',
    }
}


export const appTheme: ThemeOptions = {
    palette: {
        mode: 'light',
        common: {
            white: appColor.grey[0],
            black: appColor.grey[100]
        },
        primary: {
            main: '#000',
        },
        secondary: {
            dark: appColor.sec.d,
            main: appColor.sec.m,
            light: appColor.sec.l,
        },
        text: {
            primary: appColor.text.pri,
            secondary: appColor.text.sec
        },
        background: {
            default: appColor.other.bg,
            paper: appColor.other.paper
        },
        grey: {
            '50': appColor.grey[5],
            '100': appColor.grey[10],
            '200': appColor.grey[20],
            '300': appColor.grey[30],
            '400': appColor.grey[40],
            '500': appColor.grey[50],
            '600': appColor.grey[60],
            '700': appColor.grey[70],
            '800': appColor.grey[80],
            '900': appColor.grey[90]
        },
        divider: appColor.other.divider,
    },
    typography: {
        fontFamily: '"Mulish", sans-serif'
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    padding: 0
                },
                notchedOutline: {
                    borderColor: appColor.other.divider,
                },
                input: {
                    padding: '15px 16px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    lineHeight: '150%',
                    color: appColor.text.pri,
                    textTransform: 'none',
                    borderRadius: '100px',
                    padding: '15px 22px',
                },
                contained: {
                    color: '#fff'
                },
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: 14,
                    lineHeight: '150%',
                    color: appColor.text.sec
                }
            }
        },
    }
}