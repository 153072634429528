import {
  Box,
  Container,
  IconButton,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { useAppTheme } from "../../core/useAppTheme";
import { ArrowBack } from "@mui/icons-material";

interface DataProps {
  children: ReactNode;
  title?: string;
  text?: string;
  image?: string;
  sx?: SxProps<Theme>;
  backRoute?: string;
}

export const AuthLayout: FC<DataProps> = ({
  children,
  title,
  text,
  image,
  backRoute,
  sx,
}) => {
  const { tablet, appColor, navigate } = useAppTheme();

  return (
    <Box
      height="100vh"
      display="flex"
      bgcolor={"#000000"}
      overflow="hidden"
      position="relative"
      sx={sx}
    >
      {backRoute ? (
        <IconButton
          sx={{
            position: "absolute",
            top: tablet ? 16 : 24,
            left: tablet ? 16 : 24,
            color: "common.white",
            zIndex: 100,
            bgcolor: `${appColor.other.whitetrans} !important`,
          }}
          onClick={() => {
            navigate(backRoute);
          }}
        >
          <ArrowBack />
        </IconButton>
      ) : (
        <></>
      )}
      {tablet ? (
        <></>
      ) : (
        <Box flex={1} position="relative">
          <Box flex={1} width="100%" height="100%" component="figure">
            <Box
              src={image}
              component="img"
              alt="background"
              width="100%"
              height="100%"
              sx={{ objectFit: "contain" }}
            />
          </Box>
          <Box position="absolute" top={0} left={36} width="100%" height="100%">
            <Container>
              <Typography
                color="#fff"
                lineHeight="130%"
                pt={7}
                fontSize={28}
                fontWeight={700}
              >
                {title}
              </Typography>
              <Typography color="#fff" lineHeight="150%" variant="body1" pt={1}>
                {text}
              </Typography>
            </Container>
          </Box>
        </Box>
      )}
      <Box
        flex={1}
        bgcolor="background.default"
        borderRadius={tablet ? "0px" : "38px 0px 0px 38px"}
        className="main-card"
        sx={{ overflowY: "auto" }}
      >
        {children}
      </Box>
    </Box>
  );
};
