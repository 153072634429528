"use client";
import { Box, MenuItem, TextField, Typography } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface optionsProps {
  value: string | number;
  text: string;
}

interface SelectProps {
  defineOptions?: optionsProps[];
  options?: string[];
  name: string;
  label: string;
  placeholder: string;
  helpText?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  startAdornment?: JSX.Element;
}

export const FormSelect: FC<SelectProps> = ({
  name,
  options,
  defineOptions,
  helpText,
  label,
  placeholder,
  autoFocus,
  disabled,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const isError = errors?.[name]?.message ? true : false;
  const errorMessage = errors?.[name]?.message ? errors?.[name]?.message : "";

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography color="text.secondary" lineHeight="150%" fontSize={14}>
        {label}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            fullWidth={true}
            disabled={disabled}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onChange={onChange}
            variant="outlined"
            name={name}
            error={isError}
            helperText={<>{isError ? errorMessage : helpText}</>}
            style={{ width: "100%" }}
            select
          >
            {defineOptions
              ? defineOptions?.map((e, index) => {
                  return (
                    <MenuItem value={e.value} key={index}>
                      {e.text}
                    </MenuItem>
                  );
                })
              : options?.map((e, index) => {
                  return (
                    <MenuItem value={e} key={index}>
                      {e}
                    </MenuItem>
                  );
                })}
          </TextField>
        )}
      />
    </Box>
  );
};
