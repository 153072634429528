"use client";
import { Box, FormHelperText, Typography } from "@mui/material";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { CheckBoxDataModel, FormCheckBox } from "./FormCheckBox";
interface DataModel {
  name: string;
  options: CheckBoxDataModel[];
  label: string;
  helpText?: string;
}

export const FormMultiCheckBox: FC<DataModel> = ({
  name,
  label,
  helpText,
  options,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const isError = errors?.[name]?.root?.message ? true : false;
  const errorMessage = errors?.[name]?.root?.message
    ? errors?.[name]?.root?.message
    : helpText;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography color="text.primary" variant="body1" fontWeight={600} fontSize={18}>
        {label}
      </Typography>
      <Box display="flex" flexDirection="column">
        {options.map((e, key) => {
          return <FormCheckBox key={key} {...e} />;
        })}
        <FormHelperText error={isError}>
          <>{errorMessage}</>
        </FormHelperText>
      </Box>
    </Box>
  );
};
