import { Box, Button, Chip, Typography } from "@mui/material";
import React from "react";
import { AppTable } from "../card/AppTable";

const TransactionDetail = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      border={"1px solid red"}
      borderColor={"#eeeeee"}
      borderRadius={3}
    >
      <Typography color={"text.primary"} variant="h6" fontWeight={700} p={3}>
        Transaction History
      </Typography>
      <AppTable
        colSpan={[2, 2, 1, 1, 1, 1]}
        pagination={true}
        headings={[
          "Rental Name",
          "Owner Name",
          "Date",
          "Status",
          "Rent",
          "Fee",
        ]}
        row={[
          [
            "Susan",
            "Arafat Mahfuz",
            "10/1/2021 - 12/30/2023",
            <Chip label="Pending" color="warning" variant="outlined" />,
            "500$",
            "20$",
          ],
          [
            "Susan",
            "Arafat Mahfuz",
            "10/1/2021 - 12/30/2023",
            <Chip label="Complete" color="success" variant="outlined" />,
            "500$",
            "20$",
          ],
          [
            "Susan",
            "Arafat Mahfuz",
            "10/1/2021 - 12/30/2023",
            <Chip label="Complete" color="success" variant="outlined" />,
            "500$",
            "20$",
          ],
        ]}
      />
    </Box>
  );
};

export default TransactionDetail;
