"use client";
import { Box, SxProps, TextField, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface PropsType {
  name: string;
  label?: string;
  sx?: SxProps<Theme>;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
  minRows?: number;
  type?: React.HTMLInputTypeAttribute;
  maxRows?: number;
  maxLength?: number;
  helpText?: string;
}

export const FormTextArea: FC<PropsType> = ({
  name,
  placeholder,
  autoFocus,
  sx,
  disabled = false,
  minRows = 1,
  maxRows = 5,
  type,
  maxLength,
  label,
  helpText,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const isError = errors?.[name]?.message ? true : false;
  const errorMessage = errors?.[name]?.message ? errors?.[name]?.message : "";

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography color="text.secondary" lineHeight="150%" fontSize={14}>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            sx={sx}
            variant="outlined"
            placeholder={placeholder}
            autoFocus={autoFocus}
            type={type}
            error={isError}
            minRows={minRows}
            maxRows={maxRows}
            inputProps={{ maxLength: maxLength }}
            helperText={<>{isError ? errorMessage : helpText}</>}
            disabled={disabled}
            multiline
            fullWidth
          />
        )}
      />
    </Box>
  );
};
