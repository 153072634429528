import { Box, Typography } from "@mui/material";
import GoogleMapReact from "google-map-react";
import React, { useState } from "react";
import { envVariables } from "../../env";

const AddressDetail = () => {
  const [mark, setMark] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
  });

  const data = [
    {
      title: "Address",
      text: "10425 Tabor St",
    },
    {
      title: "City",
      text: "Los Angeles",
    },
    {
      title: "State/county",
      text: "California",
    },
    {
      title: "Zip/Postal Code",
      text: "90034",
    },
    {
      title: "Area",
      text: "Brookside",
    },
    {
      title: "Country",
      text: "United States",
    },
  ];

  return (
    <Box
      p={"30px"}
      display="flex"
      flexDirection="column"
      gap={3}
      border={"1px solid red"}
      borderColor={"#eeeeee"}
      borderRadius={3}
    >
      <Typography fontSize={17} color={"text.primary"} fontWeight={700}>
        Address
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1.5}>
        {data.map((e, key) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              width={"calc((100% - 16px) / 2)"}
            >
              <Typography
                color={"text.primary"}
                variant="body2"
                fontWeight={700}
                flex={1}
              >
                {e.title}
              </Typography>
              <Typography color={"text.primary"} variant="body2" flex={1}>
                {e.text}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "300px",
          borderRadius: 3,
          overflow: 'hidden',
          position: "relative",
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{ key: envVariables.googleMapKey }}
          defaultZoom={11}
          defaultCenter={mark}
        />
      </Box>
    </Box>
  );
};

export default AddressDetail;
