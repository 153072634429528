import { Box } from "@mui/material";
import { DashboardLayout } from "../components";
import BasicDetail from "../components/detail/BasicDetail";
import OverView from "../components/detail/OverView";
import DetailDiscription from "../components/detail/DetailDiscription";
import PropertyDetailImages from "../components/detail/PropertyDetailImages";
import AddressDetail from "../components/detail/AddressDetail";
import AmenitiesDetail from "../components/detail/AmenitiesDetail";
import TransactionDetail from "../components/detail/TransactionDetail";

const PropertyDetailsPage = () => {
  return (
    <DashboardLayout>
      <Box py={4}>
        <PropertyDetailImages
          data={[
            "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ]}
        />
      </Box>
      <BasicDetail />
      <Box pt={4} pb={"30px"}>
        <OverView />
      </Box>
      <DetailDiscription />
      <Box py={"30px"}>
        <AddressDetail />
      </Box>
      <AmenitiesDetail />
      <Box py={"30px"}>
        <TransactionDetail />
      </Box>
    </DashboardLayout>
  );
};

export default PropertyDetailsPage;
