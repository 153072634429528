import * as yup from "yup";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AuthLayout,
  FormCheckBox,
  FormPasswordField,
  FormTextField,
} from "../components";
import { authBG } from "../assets/images";
import { appleLogo, googleLogo } from "../images";
import { useAppTheme } from "../core/useAppTheme";

const SignupPage = () => {
  const { theme, tablet, navigate } = useAppTheme();

  const formSchema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup
      .string()
      .email("Incorrect Email Address")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be 6 to 24 characters long")
      .max(24, "Password must be 6 to 24 characters long"),
    confirmPassword: yup
      .string()
      .oneOf(
        [yup.ref("password"), ""] as const,
        "Password and conform password must must be same"
      )
      .required("Conform password is required"),
    agree: yup
      .boolean()
      .test(
        "is-true",
        "Please agree to our Terms & Privacy",
        (value) => value === true
      ),
  });

  // react hook form
  const FormMethod = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      agree: false,
    },
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit } = FormMethod;

  // post funtion
  const submitForm = async (data: any) => {
    console.log(data);
    navigate('/dashboard');
  };

  return (
    <AuthLayout image={authBG} backRoute="/login">
      <Container maxWidth="sm" sx={{ pt: 10, pb: 8 }}>
        <Typography lineHeight="130%" fontWeight={700} fontSize={28}>
          Sign Up
        </Typography>
        <FormProvider {...FormMethod}>
          <Box
            onSubmit={handleSubmit(submitForm)}
            component="form"
            display="flex"
            gap={3}
            flexDirection="column"
            pt={3}
          >
            <Box display="flex" gap={3}>
              <FormTextField
                name="firstName"
                label="First Name"
                placeholder="Enter your first name"
              />
              <FormTextField
                name="lastName"
                label="Last Name"
                placeholder="Enter your last name"
              />
            </Box>
            <FormTextField
              name="email"
              label="Email Address"
              placeholder="Enter your email address"
            />
            <Box display="flex" gap={3}>
              <FormPasswordField
                name="password"
                label="Password"
                placeholder="Enter password"
              />
              <FormPasswordField
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Enter confirm password"
              />
            </Box>
            <FormCheckBox name="agree" label="I Agree to the Terms & Privacy" />
            <Button
              variant="contained"
              size="large"
              type="submit"
              disableElevation
              fullWidth
              sx={{
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "150%",
                py: tablet ? 1.25 : 2,
              }}
            >
              Sign Up
            </Button>
            <Box width="100%" display="flex" alignItems="center">
              <Divider sx={{ flex: 1 }} />
              <Typography
                color="text.secondary"
                fontSize={14}
                fontWeight={500}
                lineHeight="150%"
                px={3}
              >
                or
              </Typography>
              <Divider sx={{ flex: 1 }} />
            </Box>
            <Box display="flex" gap={3}>
              <Button
                startIcon={
                  <img src={googleLogo} height={20} alt="google logo" />
                }
                sx={{ borderRadius: 2.5, borderColor: "divider", py: 1.25 }}
                variant="outlined"
                color="inherit"
                fullWidth
              >
                Google
              </Button>
              <Button
                startIcon={<img src={appleLogo} height={20} alt="apple logo" />}
                sx={{ borderRadius: 2.5, borderColor: "divider", py: 1.25 }}
                variant="outlined"
                color="inherit"
                fullWidth
              >
                Apple
              </Button>
            </Box>
            <Typography
              fontSize={14}
              color="text.secondary"
              lineHeight="150%"
              textAlign="center"
              pt={1}
            >
              2023 Citisum. All Right Reserved
            </Typography>
          </Box>
        </FormProvider>
      </Container>
    </AuthLayout>
  );
};

export default SignupPage;
