import {
  ArrowBackOutlined,
  ArrowDownward,
  ArrowForwardOutlined,
  ArrowUpwardOutlined,
  CloudUpload,
  Delete,
  Done,
} from "@mui/icons-material";
import {
  Box,
  Typography,
  Paper,
  IconButton,
  Chip,
  FormHelperText,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useAppTheme } from "../../core/useAppTheme";
import { useFormContext } from "react-hook-form";

interface DataModel {
  files: File[];
  setFiles: Function;
  onDelete: (index: number) => void;
  label?: string;
  name: string;
}

const ImagePicker: FC<DataModel> = ({
  files,
  setFiles,
  onDelete,
  label,
  name,
}) => {
  const { mobile } = useAppTheme();
  const [dragOver, setDragOver] = useState(false);

  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const isError = errors?.[name]?.message ? true : false;
  const errorMessage = errors?.[name]?.message ? errors?.[name]?.message : "";

  const changeOrderOfModel = (currentIndex: number, newIndex: number) => {
    const newArray = [...files]; // Create a shallow copy of the array
    const [movedElement] = newArray.splice(currentIndex, 1); // Remove the element from the current index
    newArray.splice(newIndex, 0, movedElement); // Insert the element at the new index
    setFiles(newArray); // Update the state with the modified array
  };

  useEffect(() => {
    setValue(name, files.length);
  }, [files]);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {label ? (
        <Typography color="text.secondary" lineHeight="150%" fontSize={14}>
          {label}
        </Typography>
      ) : (
        <></>
      )}
      <Paper
        variant="outlined"
        onDragOver={(e) => {
          e.preventDefault();
          setDragOver(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setDragOver(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setDragOver(false);
          if (e.dataTransfer.files) {
            const data = [...files, ...(e.dataTransfer.files as any)];
            setFiles(data);
          }
        }}
        style={{
          border: dragOver ? "2px dashed #000" : "2px dashed #aaa",
          background: dragOver ? "#eee" : "#fafafa",
          padding: 20,
          textAlign: "center",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              const data = [...files, ...(e.target.files as any)];
              setFiles(data);
            }
          }}
        />
        <label htmlFor="raised-button-file">
          <Box display="flex" flexDirection="column" alignItems="center">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloudUpload style={{ fontSize: 60 }} />
            </IconButton>
            <Typography>
              Drag and drop files here or click to select files
            </Typography>
          </Box>
        </label>
      </Paper>
      <FormHelperText error={isError}>
        <>{errorMessage}</>
      </FormHelperText>
      <Box gap={2.5} display="flex" flexWrap="wrap" mt={2}>
        {files.map((e, key) => {
          return (
            <Box
              key={key}
              sx={{ aspectRatio: "2/2" }}
              width={
                mobile ? "calc((100% - 20px) / 2)" : "calc((100% - 40px) / 3)"
              }
              borderRadius={2.5}
              overflow="hidden"
              position="relative"
            >
              <IconButton
                color="error"
                sx={{ position: "absolute", right: 2, top: 2 }}
                onClick={() => onDelete(key)}
              >
                <Delete sx={{ color: "error.light" }} />
              </IconButton>
              <Box
                component="img"
                src={URL.createObjectURL(e)}
                alt="Image Preview"
                sx={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              {key === 0 ? (
                <Chip
                  icon={<Done />}
                  label="Cover Image"
                  variant="filled"
                  color="default"
                  size="small"
                  sx={{
                    position: "absolute",
                    top: 8,
                    left: 8,
                    zIndex: 10,
                    background: "white",
                  }}
                />
              ) : (
                <></>
              )}
              <Box
                display="flex"
                position="absolute"
                bottom={0}
                right={0}
                gap={0.5}
              >
                <IconButton
                  color="secondary"
                  size="small"
                  disabled={key == 0}
                  onClick={() => {
                    changeOrderOfModel(key, key - 1);
                  }}
                >
                  <ArrowBackOutlined />
                </IconButton>
                <IconButton
                  color="secondary"
                  size="small"
                  disabled={files.length - 1 === key}
                  onClick={() => {
                    changeOrderOfModel(key, key + 1);
                  }}
                >
                  <ArrowForwardOutlined />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ImagePicker;
