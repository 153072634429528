const dev = true;

const devEnv = {
    googleMapKey: "AIzaSyCuewA9ei2ujr6hrbWfK34_aX-Ta6ExgaI"
}

const prodEnv = {
    googleMapKey: "AIzaSyCuewA9ei2ujr6hrbWfK34_aX-Ta6ExgaI"
}

export const envVariables = dev ? devEnv: prodEnv;