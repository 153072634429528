import { Box, Typography } from "@mui/material";
import React from "react";

const AmenitiesDetail = () => {
  const amunitiesList = [
    "Towels, bed sheets, soap, and toilet paper",
    "Wifi",
    "Smoke detector",
    "Carbon monoxide detector",
    "Parking",
    "Swimming Pool",
    "Washer/Dryer",
    "Alarm System",
  ];

  return (
    <Box
      p={"30px"}
      display="flex"
      flexDirection="column"
      gap={3}
      border={"1px solid red"}
      borderColor={"#eeeeee"}
      borderRadius={3}
    >
      <Typography color={"text.primary"} variant="h6" fontWeight={700}>
        Features & Amenities
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1.5}>
        {amunitiesList.map((e, key) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              width={"calc((100% - 32px) / 3)"}
              gap={1}
            >
              <Box
                width={5}
                height={5}
                borderRadius={2}
                bgcolor="text.primary"
              />
              <Typography color={"text.primary"} variant="body2" flex={1}>
                {e}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AmenitiesDetail;
