import { useAppTheme } from "../core/useAppTheme";
import { DashboardLayout } from "../components";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  DescriptionOutlined,
  AddCardOutlined,
  MoreVert,
} from "@mui/icons-material";
import { AppTable } from "../components/card/AppTable";
import { useState } from "react";

const PropertyLedger = () => {
  const { mobile } = useAppTheme();
  const [value, setValue] = useState(0);

  return (
    <DashboardLayout
      title="Good morning"
      // action={
      //   <Box display="flex" gap={2} width={mobile ? "100%" : "fit-content"}>
      //     <Button
      //       variant="outlined"
      //       color="inherit"
      //       startIcon={<DescriptionOutlined />}
      //       sx={{
      //         borderColor: "divider",
      //         width: mobile ? "100%" : "fit-content",
      //       }}
      //     >
      //       Invoice
      //     </Button>
      //     <Button
      //       variant="contained"
      //       startIcon={<AddCardOutlined sx={{ fontSize: 20 }} />}
      //       sx={{
      //         width: mobile ? "100%" : "fit-content",
      //       }}
      //     >
      //       Payout Method
      //     </Button>
      //   </Box>
      // }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          sx={{ mt: 2 }}
          onChange={(e, value) => {
            setValue(value);
          }}
        >
          <Tab color="seconadary" label="All" value={0} />
          <Tab color="seconadary" label="Current" value={1} />
          <Tab color="seconadary" label="History" value={2} />
        </Tabs>
      </Box>
      <Box pt={5} pb={2} display="flex" gap={1}>
        <TextField
          variant="outlined"
          placeholder="Select"
          defaultValue={"all"}
          onChange={(e) => {}}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "5px",
            },
            "& .MuiSelect-select": {
              pl: 2,
            },
          }}
          select
        >
          <MenuItem value={"all"}>All Properties</MenuItem>
          <MenuItem value={"susan"}>3 Industrial - A l Julie Russell</MenuItem>
        </TextField>
        <TextField
          variant="outlined"
          placeholder="Select"
          size="small"
          defaultValue={"all"}
          onChange={(e) => {}}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "5px",
            },
            "& .MuiSelect-select": {
              pl: 2,
            },
          }}
          select
        >
          <MenuItem value={"all"}>All Time</MenuItem>
          <MenuItem value={"susan"}>1 Month</MenuItem>
          <MenuItem value={"susan"}>3 Month</MenuItem>
          <MenuItem value={"susan"}>6 Month</MenuItem>
          <MenuItem value={"susan"}>9 Month</MenuItem>
          <MenuItem value={"susan"}>1 Year</MenuItem>
          <MenuItem value={"susan"}>2 Year</MenuItem>
          <MenuItem value={"susan"}>3 Year</MenuItem>
        </TextField>
      </Box>
      <AppTable
        colSpan={[2, 2, 1, 1, 1, 1, 1]}
        headings={[
          "Rental Name",
          "Property Name",
          "Security Deposit",
          "Rental Rate",
          "cleaning Rate",
          "Status",
          "Issue Status",
          "Date",
          "Action",
        ]}
        row={[
          [
            "Susan",
            "3 Industrial - A l Julie Russell",
            "300$",
            "200$",
            "50$",
            <Chip label="Living" color="secondary" variant="outlined" />,
            <Chip label="Open" color="warning" variant="outlined" />,
            "10/1/2021",
            <ActionButton />,
          ],
          [
            "Susan",
            "3 Industrial - A l Julie Russell",
            "300$",
            "200$",
            "50$",
            <Chip label="Living" color="secondary" variant="outlined" />,
            <Chip label="Report" color="error" variant="outlined" />,
            "10/1/2021",
            <ActionButton />,
          ],
          [
            "Susan",
            "3 Industrial - A l Julie Russell",
            "300$",
            "200$",
            "50$",
            <Chip label="Moved" color="success" variant="outlined" />,
            <Chip label="Resolve" color="success" variant="outlined" />,
            "10/1/2021 - 12/30/2023",
            <ActionButton />,
          ],
        ]}
      />
    </DashboardLayout>
  );
};

const ActionButton = () => {
  // states
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* menu */}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            View Agreement
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            View Property
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            View Background
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            Download Invoice
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            View Issue
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body1" color="grey.800">
            Refund
          </Typography>
        </MenuItem>
      </Menu>

      {/* button */}
      <IconButton key={0} color="inherit" onClick={handleClick}>
        <MoreVert />
      </IconButton>
    </>
  );
};

export default PropertyLedger;
