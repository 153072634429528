import * as yup from "yup";
import { Box, Button, Container } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthLayout } from "../components";
import { authBG } from "../assets/images";
import { useAppTheme } from "../core/useAppTheme";
import { useState } from "react";
import {
  BasicPropertyDetails,
  PropertyAmunities,
  PropertyDetail,
  PropertyImages,
  PropertyLocation,
  PropertyMap,
  PropertyPrice,
  PropertyRules,
  PropertyServiceType,
  PropertyType,
} from "../components/steps/ListingFromSteps";

const formSchema = yup.object().shape({
  propertyType: yup.string().required("This field is required"),
  ownProperty: yup.string().required("This field is required"),
  tenderSpace: yup.string().required("This field is required"),
  preferences: yup
    .object()
    .shape({
      teens: yup.boolean(),
      babys: yup.boolean(),
      pets: yup.boolean(),
      smokes: yup.boolean(),
      parties: yup.boolean(),
    })
    .test("at-least-one", "At least select one option", (value) => {
      const { teens, babys, pets, smokes, parties } = value;
      return teens || babys || pets || smokes || parties;
    }),
  timeTerm: yup
    .object()
    .shape({
      sell: yup.boolean(),
      longTerm: yup.boolean(),
      midTerm: yup.boolean(),
      shortTerm: yup.boolean(),
    })
    .test("at-least-one", "At least select one option", (value) => {
      const { longTerm, midTerm, shortTerm, sell } = value;
      return longTerm || midTerm || shortTerm || sell;
    }),
  sellPrice: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return value;
    })
    .when("timeTerm.sell", {
      is: true,
      then: (e) =>
        e
          .min(5, "price must be more than 5$")
          .required("This field is required"),
    }),
  yearPrice: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return value;
    })
    .when("timeTerm.longTerm", {
      is: true,
      then: (e) =>
        e
          .min(5, "price must be more than 5$")
          .required("This field is required"),
    }),
  monthPrice: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return value;
    })
    .when("timeTerm.midTerm", {
      is: true,
      then: (e) =>
        e
          .min(5, "price must be more than 5$")
          .required("This field is required"),
    }),
  dayPrice: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return value;
    })
    .when("timeTerm.shortTerm", {
      is: true,
      then: (e) =>
        e
          .min(5, "price must be more than 5$")
          .required("This field is required"),
    }),
  squareFeet: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return value;
    })
    .required("This field is required"),
  bathrooms: yup.string().required("This field is required"),
  bedrooms: yup.string().required("This field is required"),
  location: yup.string().required("This field is required"),
  streetAddress: yup.string().required("This field is required"),
  optionalAddress: yup.string(),
  city: yup.string().required("This field is required"),
  state: yup.string().required("This field is required"),
  zipCode: yup
    .number()
    .nullable()
    .transform((value, originalValue) => {
      if (originalValue === "") {
        return null;
      }
      return value;
    })
    .required("This field is required"),
  listingTitle: yup.string().required("This field is required"),
  listingDec: yup.string().required("This field is required"),
  amunities: yup.array().of(yup.string().required("This field is required")),
  listingImage: yup
    .number()
    .required("This field is required")
    .min(1, "At least upload one Image"),
});

const steps = [
  ["listingTitle", "listingDec"],
  ["propertyType", "ownProperty", "tenderSpace"],
  ["squareFeet", "bathrooms", "bedrooms"],
  ["amunities"],
  ["timeTerm"],
  ["sellPrice", "yearPrice", "monthPrice", "dayPrice"],
  ["location", "streetAddress", "optionalAddress", "city", "state", "zipCode"],
  [],
  ["listingImage"],
  [],
];

const ListingForm = () => {
  const { tablet, theme } = useAppTheme();
  const [step, setStep] = useState<number>(0);
  const [files, setFiles] = useState<File[]>([]);

  // react hook form
  const FormMethod = useForm({
    defaultValues: {
      listingImage: 0,
      amunities: [],
    },
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit, clearErrors, getValues } = FormMethod;

  // post funtion
  const submitForm = async (data: any) => {
    if (steps.length === step) {
      console.log(data, "form is submit successfully");
    } else {
      setStep(step + 1);
    }
  };

  const stepsComponets = [
    <BasicPropertyDetails />,
    <PropertyType />,
    <PropertyDetail />,
    <PropertyAmunities />,
    <PropertyServiceType />,
    <PropertyPrice />,
    <PropertyLocation />,
    <PropertyMap />,
    <PropertyImages
      files={files}
      setFiles={setFiles}
      onDelete={(e) => {
        const updatedFiles = [...files.slice(0, e), ...files.slice(e + 1)];
        setFiles(updatedFiles);
      }}
    />,
    <PropertyRules />,
  ];

  return (
    <AuthLayout
      image={authBG}
      title={`Current Step #${step + 1}`}
      text="Your property listing will be complete in 10 steps"
      sx={{
        "& .main-card": {
          borderRadius: 0,
          position: "relative",
        },
      }}
    >
      <FormProvider {...FormMethod}>
        <Box
          onSubmit={handleSubmit(submitForm, (e: any) => {
            var errorFixed = true;
            steps[step].forEach((field) => {
              if (e[field]?.message || e[field]?.root?.message) {
                errorFixed = false;
              }
            });
            if (errorFixed) {
              setStep(step + 1);
              clearErrors();
            } else {
              console.log(e, getValues("timeTerm"), "Resolve this errors");
            }
          })}
          component="form"
          pb={5}
        >
          {stepsComponets[step]}
          <Box
            position="fixed"
            width={tablet ? "100%" : "50%"}
            bottom={0}
            right={0}
            borderTop="1px solid red"
            borderColor={"divider"}
            boxShadow={theme.shadows[3]}
          >
            <Container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "background.default",
                gap: 2,
                py: 2,
              }}
            >
              {step === 0 ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{ width: "100px", borderColor: "divider" }}
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              )}
              <Button variant="contained" type="submit" sx={{ width: "100px" }}>
                Next
              </Button>
            </Container>
          </Box>
        </Box>
      </FormProvider>
    </AuthLayout>
  );
};

export default ListingForm;
