import {
  BedOutlined,
  BathtubOutlined,
  EventAvailableOutlined,
  GarageOutlined,
  SquareFootOutlined,
  HomeWorkOutlined,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const OverView = () => {
  const data = [
    {
      title: "Bedroom",
      subtext: "3",
      icon: <BedOutlined />,
    },
    {
      title: "Bath",
      subtext: "2",
      icon: <BathtubOutlined />,
    },
    {
      title: "Year Built",
      subtext: "2022",
      icon: <EventAvailableOutlined />,
    },
    {
      title: "Garage",
      subtext: "2",
      icon: <GarageOutlined />,
    },
    {
      title: "Sqft",
      subtext: "1200",
      icon: <SquareFootOutlined />,
    },
    {
      title: "Property Type",
      subtext: "Apartment",
      icon: <HomeWorkOutlined />,
    },
  ];

  return (
    <Box
      p={"30px"}
      display="flex"
      flexDirection="column"
      gap={"30px"}
      border={"1px solid red"}
      borderColor={"#eeeeee"}
      borderRadius={3}
    >
      <Typography fontSize={17} color={"text.primary"} fontWeight={700}>
        Overview
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={"25px"}>
        {data.map((e, key) => {
          return (
            <Box
              key={key}
              display="flex"
              alignItems="center"
              gap={"15px"}
              width={"calc((100% - 50px) / 3)"}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={4}
                border={"1px solid red"}
                borderColor={"divider"}
                width={50}
                height={50}
                sx={{
                  "& svg": {
                    fontSize: 24,
                    color: "color.primary",
                  },
                }}
              >
                {e.icon}
              </Box>

              <Box display="flex" flexDirection="column" flex={1}>
                <Typography
                  fontSize={15}
                  color={"text.primary"}
                  fontWeight={700}
                >
                  {e.title}
                </Typography>
                <Typography fontSize={15} color={"text.primary"}>
                  {e.subtext}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default OverView;
