import { Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { DashboardLayout, RentalCard } from "../components";
import { AddOutlined } from "@mui/icons-material";
import { useAppTheme } from "../core/useAppTheme";
import React, { useState } from "react";

export const RentalsPage = () => {
  const { mobile, largeTablet, tablet } = useAppTheme();

  const [value, setValue] = useState(1);

  return (
    <DashboardLayout
      title="Rentals"
      action={
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<AddOutlined />}
          sx={{
            borderColor: "divider",
            width: mobile ? "100%" : "fit-content",
          }}
        >
          Add property
        </Button>
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          sx={{ mt: 2 }}
          onChange={(e, value) => {
            setValue(value);
          }}
        >
          <Tab color="seconadary" label="All Rentals" value={1} />
          <Tab color="seconadary" label="Paid Rentals" value={2} />
          <Tab color="seconadary" label="UnPaid Rentals" value={3} />
        </Tabs>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={
          mobile
            ? "auto"
            : tablet
            ? "auto auto"
            : largeTablet
            ? "auto auto auto"
            : "auto auto auto auto"
        }
        gap={3}
        mt={3}
      >
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
        <RentalCard />
      </Box>
    </DashboardLayout>
  );
};

export default RentalsPage;
