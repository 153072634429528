import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Drawer,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { useAppTheme } from "../../core/useAppTheme";
import {
  logo,
  realHomeLogo,
  rentalHomeLogo,
  strHomeLogo,
} from "../../assets/images";
import {
  AddOutlined,
  BugReportOutlined,
  CloseOutlined,
  DashboardOutlined,
  EmailOutlined,
  FamilyRestroomOutlined,
  HomeWorkOutlined,
  MenuOutlined,
  NotificationAddOutlined,
  NotificationsActiveOutlined,
  ReceiptLongOutlined,
  SearchOutlined,
  SettingsOutlined,
  SupportAgentOutlined,
  TextSnippetOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
interface DataProps {
  children: ReactNode;
  title?: string;
  action?: ReactNode;
  sticky?: boolean;
}

export const DashboardLayout: FC<DataProps> = ({
  children,
  title,
  action,
  sticky,
}) => {
  const { appColor, tablet, pathname, palette, mobile } = useAppTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const menuData = [
    {
      title: "MAIN MENU",
      items: [
        {
          title: "Dashboard",
          route: "/dashboard",
          icon: <DashboardOutlined />,
        },
        {
          title: "Messages",
          route: "/message",
          icon: <EmailOutlined />,
        },
        {
          title: "Listing",
          route: "/listing",
          icon: <HomeWorkOutlined />,
        },
        {
          title: "Tenants",
          route: "/tenants",
          icon: <FamilyRestroomOutlined />,
        },
        {
          title: "Payout",
          route: "/payout",
          icon: <ReceiptLongOutlined />,
        },
        // {
        //   title: "Issues",
        //   route: "/issues",
        //   icon: <BugReportOutlined />,
        // },
        {
          title: "Ledger",
          route: "/ledger",
          icon: <TextSnippetOutlined />,
        },
      ],
    },
    // {
    //   title: "RESIDENTIAL",
    //   items: [
    //     {
    //       title: "Real Home",
    //       route: "/real-home",
    //       icon: (
    //         <img
    //           src={realHomeLogo}
    //           alt="Real Home"
    //           width="20px"
    //           height="auto"
    //         />
    //       ),
    //     },
    //     {
    //       title: "Rental Home",
    //       route: "/rental-home",
    //       icon: (
    //         <img
    //           src={rentalHomeLogo}
    //           alt="Rental Home"
    //           width="20px"
    //           height="auto"
    //         />
    //       ),
    //     },
    //     {
    //       title: "STR Home",
    //       route: "/str-home",
    //       icon: (
    //         <img src={strHomeLogo} alt="STR Home" width="20px" height="auto" />
    //       ),
    //     },
    //   ],
    // },
    {
      title: "SETTINGS",
      items: [
        {
          title: "Help & Support",
          route: "/support",
          icon: <SupportAgentOutlined />,
        },
        {
          title: "Settings",
          route: "/setting",
          icon: <SettingsOutlined />,
        },
      ],
    },
  ];

  const childComponent = (
    <Container sx={{ py: 4, display: "flex", flexDirection: "column" }}>
      <Box
        display="flex"
        gap={3}
        mb={4}
        justifyContent="space-between"
        alignItems="center"
        maxWidth="100%"
      >
        <img
          src={logo}
          alt="logo"
          style={{ flex: 1, maxWidth: "calc(100% - 75px)" }}
        />
        <IconButton
          color="inherit"
          size="large"
          sx={{
            background: "#232527 !important",
            borderRadius: 3,
          }}
          onClick={() => {
            setOpenNotification(!openNotification);
          }}
        >
          <NotificationsActiveOutlined sx={{ color: "#fff" }} />
        </IconButton>
      </Box>
      {menuData.map((e, key) => {
        return (
          <Box key={key} display="flex" flexDirection="column" gap={1.5} mb={5}>
            <Typography
              fontSize={16}
              fontWeight={500}
              lineHeight="150%"
              letterSpacing="5%"
              color="#FFF"
            >
              {e.title}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={1.75}
              sx={{
                "& svg": {
                  height: 20,
                  width: 20,
                  fill: "#F9FAFA",
                },
              }}
            >
              {e.items.map((e, key) => {
                const linkstyles =
                  pathname.pathname === e.route
                    ? {
                        background: "#fff",
                        "& svg": {
                          fill: palette.text.primary,
                        },
                        "& p": {
                          color: palette.text.primary,
                        },
                      }
                    : {
                        "& p": {
                          color: "#F9FAFA",
                        },
                        "& svg": {
                          fill: "#F9FAFA",
                        },
                      };

                return (
                  <Box sx={{ ...linkstyles, borderRadius: "100px" }}>
                    <Link
                      key={key}
                      to={e.route}
                      style={{
                        padding: "14px 12px 14px 20px",
                        gap: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {e.icon}
                      <Typography fontSize={16} lineHeight="150%">
                        {e.title}
                      </Typography>
                    </Link>
                  </Box>
                );
              })}
            </Box>
          </Box>
        );
      })}
    </Container>
  );

  return (
    <Box
      width="100%"
      height="100vh"
      display="flex"
      bgcolor={"#000"}
      overflow="hidden"
    >
      {tablet ? (
        <Drawer
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false);
          }}
          sx={{
            ".MuiPaper-root": {
              bgcolor: "#000",
            },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 320 },
          }}
        >
          {childComponent}
        </Drawer>
      ) : (
        <Box width={290} sx={{ overflowY: "auto" }} className="hide-scroll-bar">
          {childComponent}
        </Box>
      )}

      <Drawer
        open={openNotification}
        anchor="right"
        onClose={() => {
          setOpenNotification(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 400,
            bgcolor: "#fff",
          },
        }}
      >
        <Container>
          <Box display="flex" gap={2} alignItems="center">
            <Typography
              variant="h5"
              fontWeight={700}
              py={2}
              color="text.primary"
              flex={1}
            >
              Notification
            </Typography>
            <IconButton
              onClick={() => {
                setOpenNotification(!openNotification);
              }}
            >
              <CloseOutlined />
            </IconButton>
          </Box>
          <Box
            display="flex"
            borderRadius={3}
            p={2}
            gap={2}
            bgcolor={"background.paper"}
            border="1px solid red"
            borderColor="divider"
            alignItems="center"
          >
            <NotificationAddOutlined sx={{ color: "grey.600", fontSize: 26 }} />
            <Box flex={1} gap={0.75} flexDirection="column">
              <Typography color="text.primary" variant="body1" fontWeight={700}>
                Push Notification
              </Typography>
              <Typography color="text.secondary" variant="body2" fontSize={12}>
                It is a long established fact that
              </Typography>
            </Box>
            <Switch color="secondary" />
          </Box>
        </Container>
        <Box display="flex" flexDirection="column">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
            return (
              <Box
                p={3}
                gap={2}
                display="flex"
                borderBottom={"1px solid red"}
                borderColor="divider"
              >
                <Box flex={1} gap={2} display="flex">
                  <Avatar
                    sizes={"32"}
                    sx={{ mt: 0.5 }}
                    src="https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <Box flex={1} gap={0.75} flexDirection="column">
                    <Typography
                      color="text.primary"
                      variant="body1"
                      fontWeight={700}
                    >
                      Arafat Mahfuz
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      fontSize={12}
                    >
                      It is a long established fact that a reader will be dis -
                      tracted by the readable content.
                    </Typography>
                    <Box display="flex" gap={1} pt={1}>
                      <Chip
                        label="Review"
                        variant="outlined"
                        size="small"
                        sx={{ color: "grey.500" }}
                        onClick={() => {}}
                      />
                      <Chip
                        label="Message"
                        size="small"
                        color="success"
                        variant="outlined"
                        onClick={() => {}}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box
                  borderRadius={2.5}
                  width={64}
                  height={64}
                  bgcolor={"background.paper"}
                  overflow="hidden"
                >
                  <img
                    width="100%"
                    height="100%"
                    alt="your-property"
                    src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    style={{ objectFit: "cover" }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Drawer>
      <Box
        flex={1}
        bgcolor="background.default"
        borderRadius={tablet ? "0px" : "38px 0px 0px 38px"}
        className="main-card"
        sx={{ overflowY: "auto", py: tablet ? 2 : 4 }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            top: 0,
            position: sticky ? "sticky" : "relative",
          }}
        >
          {tablet ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <IconButton
                onClick={() => {
                  setOpenDrawer(true);
                }}
              >
                <MenuOutlined sx={{ fontSize: 32 }} />
              </IconButton>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar
                  sx={{ width: 50, height: 50 }}
                  src="https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                />
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box maxWidth="350px" flex={1}>
                <TextField
                  variant="outlined"
                  placeholder="Search here.."
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 12,
                      paddingLeft: "12px",
                    },
                    "& input": {
                      padding: "12px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <SearchOutlined
                        sx={{ color: "#32393C", width: 20, height: 20 }}
                      />
                    ),
                  }}
                  fullWidth
                />
              </Box>
              <Box display="flex" alignItems="center" gap={2}>
                <Box display="flex" gap={1}>
                  <Avatar
                    sx={{ width: 50, height: 50 }}
                    src="https://images.unsplash.com/photo-1531891437562-4301cf35b7e4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                  <Box>
                    <Typography
                      fontSize={18}
                      lineHeight="150%"
                      fontWeight={600}
                      color="text.primary"
                    >
                      Arafat Mahfuz
                    </Typography>
                    <Typography
                      fontSize={14}
                      lineHeight="150%"
                      color="text.secondary"
                    >
                      UIDesigner
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {title || action ? (
            <Box
              display="flex"
              alignItems={mobile ? "start" : "center"}
              justifyContent="space-between"
              flexDirection={mobile ? "column" : "row"}
              gap={2}
              pt={mobile ? 2 : tablet ? 3 : 4}
            >
              <Typography fontSize={32} fontWeight={600} lineHeight="130%">
                {title}
              </Typography>
              {action}
            </Box>
          ) : (
            <></>
          )}
        </Container>
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};
