"use client";
import {
  Box,
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { FC, HTMLInputTypeAttribute } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface PropsType {
  name: string;
  sx?: SxProps<Theme>;
  placeholder: string;
  autoFocus?: boolean;
  id?: string;
  type?: HTMLInputTypeAttribute;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>;
  disabled?: boolean;
  helpText?: string;
  label?: string;
}

export const FormTextField: FC<PropsType> = ({
  name,
  sx,
  placeholder,
  autoFocus,
  type = "text",
  InputProps,
  label,
  helpText,
  id,
  disabled = false,
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();
  const isError = errors?.[name]?.message ? true : false;
  const errorMessage = errors?.[name]?.message ? errors?.[name]?.message : "";

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography color="text.secondary" lineHeight="150%" fontSize={14}>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            sx={sx}
            id={id}
            variant="outlined"
            placeholder={placeholder}
            autoFocus={autoFocus}
            InputProps={InputProps}
            type={type}
            error={isError}
            helperText={<>{isError ? errorMessage : helpText}</>}
            disabled={disabled}
            fullWidth
          />
        )}
      />
    </Box>
  );
};
