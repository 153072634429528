import { AddOutlined } from "@mui/icons-material";
import { DashboardLayout } from "../components";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { useAppTheme } from "../core/useAppTheme";
import { useState } from "react";
import { ListingCard } from "../components";
import { Link } from "react-router-dom";

const ListingsPage = () => {
  const { mobile, largeTablet, tablet } = useAppTheme();

  const [value, setValue] = useState(1);

  return (
    <DashboardLayout
      title="Listings"
      action={
        <Link to={"/listing/add"}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<AddOutlined />}
            sx={{
              borderColor: "divider",
              width: mobile ? "100%" : "fit-content",
            }}
          >
            Add property
          </Button>
        </Link>
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          sx={{ mt: 2 }}
          onChange={(e, value) => {
            setValue(value);
          }}
        >
          <Tab color="seconadary" label="Popular listing" value={1} />
          <Tab color="seconadary" label="Latest listing" value={2} />
          <Tab color="seconadary" label="Older listing" value={3} />
        </Tabs>
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={
          mobile ? "auto" : tablet ? "auto auto" : "auto auto auto"
        }
        gap={3}
        mt={3}
      >
        <ListingCard />
        <ListingCard />
        <ListingCard />
        <ListingCard />
        <ListingCard />
        <ListingCard />
        <ListingCard />
        <ListingCard />
        <ListingCard />
      </Box>
    </DashboardLayout>
  );
};

export default ListingsPage;
