"use client";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Box,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { FC, ReactNode, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface PropsType {
  name: string;
  placeholder: string;
  sx?: SxProps<Theme>;
  label: ReactNode;
  autoFocus?: boolean;
  startAdornment?: JSX.Element;
  disabled?: boolean;
  helpText?: string;
}

export const FormPasswordField: FC<PropsType> = ({
  name,
  placeholder,
  sx,
  label,
  autoFocus,
  helpText,
  startAdornment,
  disabled = false,
}) => {
  const [ShowPassword, setShowPassword] = useState<boolean>(false);
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const isError = errors?.[name]?.message ? true : false;
  const errorMessage = errors?.[name]?.message ? errors?.[name]?.message : "";

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography color="text.secondary" lineHeight="150%" fontSize={14}>
        {label}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <TextField
            {...field}
            sx={sx}
            variant="outlined"
            placeholder={placeholder}
            autoFocus={autoFocus}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setShowPassword(!ShowPassword);
                  }}
                >
                  {ShowPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              ),
              startAdornment: startAdornment,
            }}
            type={ShowPassword ? "text" : "password"}
            error={isError}
            helperText={<>{isError ? errorMessage : helpText}</>}
            disabled={disabled}
            fullWidth
          />
        )}
      />
    </Box>
  );
};
