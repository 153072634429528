import { useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { appColor } from '../theme/theme';

export const useAppTheme = () => {
    const theme = useTheme();
    const { palette, shadows } = theme;
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    const tablet = useMediaQuery(theme.breakpoints.down(900));
    const largeTablet = useMediaQuery(theme.breakpoints.down(1200));
    const navigate = useNavigate();
    const pathname = useLocation();
    const params = useParams();

    return {
        theme,
        palette,
        appColor,
        shadows,
        mobile,
        tablet,
        largeTablet,
        navigate,
        pathname,
        params
    }
}