import React from "react";
import { DashboardLayout } from "../components";
import { Button } from "@mui/material";
import { useAppTheme } from "../core/useAppTheme";

const SupportPage = () => {
  const { mobile } = useAppTheme();
  return (
    <DashboardLayout
      title="Help & Support"
      action={
        <Button
          variant="outlined"
          color="inherit"
          sx={{
            borderColor: "divider",
            width: mobile ? "100%" : "fit-content",
          }}
        >
          Context Us
        </Button>
      }
    >
      <></>
    </DashboardLayout>
  );
};

export default SupportPage;
