import { Box, Typography } from "@mui/material";
import { useState } from "react";

const DetailDiscription = () => {
  const [expend, setExpend] = useState(false);

  const discription =
    "This 3-bed with a loft, 2-bath home in the gated community of The Hideout has it all. From the open floor plan to the abundance of light from the windows, this home is perfect for entertaining. The living room and dining room have vaulted ceilings and a beautiful fireplace. You will love spending time on the deck taking in the beautiful views. In the kitchen, you'll find stainless steel appliances and a tile backsplash, as well as a breakfast bar. This 3-bed with a loft, 2-bath home in the gated community of The Hideout has it all. From the open floor plan to the abundance of light from the windows, this home is perfect for entertaining. The living room and dining room have vaulted ceilings and a beautiful fireplace. You will love spending time on the deck taking in the beautiful views. In the kitchen, you'll find stainless steel appliances and a tile backsplash, as well as a breakfast bar.";

  const data = [
    {
      title: "Property ID",
      text: "RT48",
    },
    {
      title: "Price",
      text: "$252,000",
    },
    {
      title: "Property Size",
      text: "1500 Sq Ft",
    },
    {
      title: "Bathrooms",
      text: "3",
    },
    {
      title: "Bedrooms",
      text: "2",
    },
    {
      title: "Garage",
      text: "2",
    },
    {
      title: "Garage Size",
      text: "200 SqFt",
    },
    {
      title: "Year Built",
      text: "2022",
    },
    {
      title: "Property Type",
      text: "Apartment",
    },
    {
      title: "Property Status",
      text: "For Sale",
    },
  ];

  return (
    <Box
      p={"30px"}
      display="flex"
      flexDirection="column"
      gap={3}
      border={"1px solid red"}
      borderColor={"#eeeeee"}
      borderRadius={3}
    >
      <Typography fontSize={17} color={"text.primary"} fontWeight={700}>
        Property Description
      </Typography>
      <Box display="flex" flexDirection="column" gap={"10px"}>
        <Typography variant="body2" color={"text.primary"} lineHeight={"26px"}>
          {expend ? discription : `${discription.slice(0, 500)}...`}
        </Typography>
        <Typography
          variant="body2"
          color={"text.primary"}
          fontWeight={600}
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => {
            setExpend(!expend);
          }}
        >
          {expend ? "Hide more" : "Show more"}
        </Typography>
      </Box>
      <Typography fontSize={17} color={"text.primary"} fontWeight={700} pt={3}>
        Property Details
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1.5}>
        {data.map((e, key) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              width={"calc((100% - 16px) / 2)"}
            >
              <Typography
                color={"text.primary"}
                variant="body2"
                fontWeight={700}
                flex={1}
              >
                {e.title}
              </Typography>
              <Typography color={"text.primary"} variant="body2" flex={1}>
                {e.text}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default DetailDiscription;
