import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/Login";
import SignupPage from "./pages/Signup";
import DashboardPage from "./pages/Dashboard";
import ForgetPasswordPage from "./pages/ForgetPassword";
import RentalsPage from "./pages/Rentals";
import ListingsPage from "./pages/Listings";
import SupportPage from "./pages/Support";
import SettingPage from "./pages/Setting";
import PayoutPage from "./pages/payout";
import ListingForm from "./pages/ListingForm";
import PropertyLedger from "./pages/PropertyLedger";
import IssuesPage from "./pages/Issues";
import NotificationPage from "./pages/Notification";
import MessagePage from "./pages/Message";
import PropertyDetailsPage from "./pages/PropertyDetails";
import "./theme/global.css";
import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={LoginPage} />
        <Route path="/login" Component={LoginPage} />
        <Route path="/forget-password" Component={ForgetPasswordPage} />
        <Route path="/signup" Component={SignupPage} />
        <Route path="/dashboard" Component={DashboardPage} />
        <Route path="/payout" Component={PayoutPage} />
        <Route path="/ledger" Component={PropertyLedger} />
        <Route path="/issues" Component={IssuesPage} />
        <Route path="/listing" Component={ListingsPage} />
        <Route path="/listing/123" Component={PropertyDetailsPage} />
        <Route path="/listing/add" Component={ListingForm} />
        <Route path="/message" Component={ MessagePage} />
        <Route path="/notification" Component={NotificationPage} />
        <Route path="/setting" Component={SettingPage} />
        <Route path="/tenants" Component={RentalsPage} />
        <Route path="/support" Component={SupportPage} />
        <Route path="/setting" Component={SettingPage} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
