import * as yup from "yup";
import { Box, Button, Container, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AuthLayout, FormPasswordField, FormTextField } from "../components";
import { authBG } from "../assets/images";
import { useAppTheme } from "../core/useAppTheme";

const ForgetPasswordPage = () => {
  const { tablet, navigate } = useAppTheme();

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .email("Incorrect Email Address")
      .required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Password must be 6 to 24 letter long")
      .max(24, "Password must be 6 to 24 letter long"),
    code: yup
      .string()
      .required("OTP is required")
      .length(6, "OTP must 6 letter long"),
  });

  // react hook form
  const FormMethod = useForm({
    defaultValues: {
      email: "",
      code: "",
      password: "",
    },
    resolver: yupResolver(formSchema),
  });
  const { handleSubmit } = FormMethod;

  // post funtion
  const submitForm = async (data: any) => {
    console.log(data);
    navigate("/dashboard");
  };

  return (
    <AuthLayout image={authBG} backRoute="/login">
      <Container maxWidth="sm" sx={{ pt: tablet ? 15 : 10, pb: 8 }}>
        <Typography lineHeight="130%" fontWeight={700} fontSize={28}>
          Forget Password
        </Typography>
        <FormProvider {...FormMethod}>
          <Box
            onSubmit={handleSubmit(submitForm)}
            component="form"
            display="flex"
            gap={3}
            flexDirection="column"
            pt={3}
          >
            <FormTextField
              name="email"
              label="Email Address"
              placeholder="Enter your email"
            />
            <FormTextField
              name="code"
              label="Enter OTP"
              placeholder="Enter OTP"
            />
            <FormPasswordField
              name="password"
              label={"New Password"}
              placeholder="Enter new password"
            />
            <Button
              variant="contained"
              size="large"
              type="submit"
              disableElevation
              fullWidth
              sx={{
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "150%",
                mt: 2,
                py: tablet ? 1.25 : 2,
              }}
            >
              Change Password
            </Button>
            <Typography
              fontSize={14}
              color="text.secondary"
              lineHeight="150%"
              pt={1}
              textAlign={"center"}
            >
              2023 Citisum. All Right Reserved
            </Typography>
          </Box>
        </FormProvider>
      </Container>
    </AuthLayout>
  );
};

export default ForgetPasswordPage;
