import {
  BathtubOutlined,
  BedOutlined,
  FmdGoodOutlined,
  MoreHorizOutlined,
  SquareFootOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useAppTheme } from "../../core/useAppTheme";
import { Link } from "react-router-dom";

export const ListingCard = () => {
  const { shadows } = useAppTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Link to={"/listing/123"}>
      <Box
        p={2}
        borderRadius={2.5}
        border={"1px solid red"}
        borderColor="divider"
        display="flex"
        flexDirection="column"
        gap={1.5}
        sx={{
          cursor: "pointer",
          transitionDuration: "0.2s",
          boxShadow: shadows[0],
          bgcolor: "#fff",
          ":hover": {
            boxShadow: shadows[1],
            bgcolor: "#F7F7F7",
          },
        }}
      >
        <Box width="100%" height={180} borderRadius={2.5} overflow="hidden">
          <img
            width="100%"
            height="100%"
            alt="rental-property"
            src="https://images.unsplash.com/photo-1600585154340-be6161a56a0c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Box display="flex" alignItems="center">
            <Typography
              fontSize={14}
              color="text.primary"
              lineHeight="150%"
              flex={1}
            >
              Garden row (multi-bulding complex)- 2B
            </Typography>
            <IconButton
              size="small"
              color="inherit"
              id="demo-positioned-button"
              aria-controls={open ? "demo-positioned-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreHorizOutlined sx={{ color: "#12171E" }} />
            </IconButton>
          </Box>
          <Typography
            fontSize={24}
            lineHeight="150%"
            fontWeight={700}
            color="secondary.main"
          >
            $750.00
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mt={0.5}
          >
            <Typography fontSize={14} lineHeight="150%" color="text.secondary">
              Listed : 07-01-2023
            </Typography>
            <Typography fontSize={14} lineHeight="150%" color="text.secondary">
              Available : 07-01-2023
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" gap={2}>
          <Box display="flex" alignItems="center" gap={1}>
            <BedOutlined sx={{ width: 20, height: 20, color: "#12171E" }} />
            <Typography
              fontSize={14}
              color="text.primary"
              lineHeight="150%"
              fontWeight={600}
            >
              1 Bed
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <BathtubOutlined sx={{ width: 20, height: 20, color: "#12171E" }} />
            <Typography
              fontSize={14}
              color="text.primary"
              lineHeight="150%"
              fontWeight={600}
            >
              2 Bath
            </Typography>
          </Box>
          <Box flex={1} />
          <Box display="flex" alignItems="center" gap={1}>
            <SquareFootOutlined
              sx={{ width: 20, height: 20, color: "#12171E" }}
            />
            <Typography
              fontSize={14}
              color="text.primary"
              lineHeight="150%"
              fontWeight={600}
            >
              700 ft2
            </Typography>
          </Box>
        </Box>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={handleClose}>View Listing</MenuItem>
          <MenuItem onClick={handleClose}>Inactive</MenuItem>
          <MenuItem onClick={handleClose}>Delete</MenuItem>
        </Menu>
      </Box>
    </Link>
  );
};
