import { useState } from "react";
import { DashboardLayout } from "../components";
import {
  Box,
  Button,
  Chip,
  MenuItem,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useAppTheme } from "../core/useAppTheme";
import { AddCardOutlined, DescriptionOutlined } from "@mui/icons-material";
import { AppTable } from "../components/card/AppTable";

const PayoutPage = () => {
  const { mobile } = useAppTheme();
  const [value, setValue] = useState(0);

  return (
    <DashboardLayout
      title="Payout"
      action={
        <Box display="flex" gap={2} width={mobile ? "100%" : "fit-content"}>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<DescriptionOutlined />}
            sx={{
              borderColor: "divider",
              width: mobile ? "100%" : "fit-content",
            }}
          >
            Invoice
          </Button>
          <Button
            variant="contained"
            startIcon={<AddCardOutlined sx={{ fontSize: 20 }} />}
            sx={{
              width: mobile ? "100%" : "fit-content",
            }}
          >
            Payout Method
          </Button>
        </Box>
      }
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="inherit"
          sx={{ mt: 2 }}
          onChange={(e, value) => {
            setValue(value);
          }}
        >
          <Tab color="seconadary" label="All" value={0} />
          <Tab color="seconadary" label="Complete" value={1} />
          <Tab color="seconadary" label="Pending" value={2} />
        </Tabs>
      </Box>
      <Box pt={5} pb={2} display="flex" gap={1}>
        <TextField
          variant="outlined"
          placeholder="Select"
          defaultValue={"all"}
          onChange={(e) => {}}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "5px",
            },
            "& .MuiSelect-select": {
              pl: 2,
            },
          }}
          select
        >
          <MenuItem value={"all"}>All Rentals</MenuItem>
          <MenuItem value={"susan"}>Susan</MenuItem>
        </TextField>
        <TextField
          variant="outlined"
          placeholder="Select"
          size="small"
          defaultValue={"all"}
          onChange={(e) => {}}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "5px",
            },
            "& .MuiSelect-select": {
              pl: 2,
            },
          }}
          select
        >
          <MenuItem value={"all"}>All Time</MenuItem>
          <MenuItem value={"susan"}>1 Month</MenuItem>
          <MenuItem value={"susan"}>3 Month</MenuItem>
          <MenuItem value={"susan"}>6 Month</MenuItem>
          <MenuItem value={"susan"}>9 Month</MenuItem>
          <MenuItem value={"susan"}>1 Year</MenuItem>
          <MenuItem value={"susan"}>2 Year</MenuItem>
          <MenuItem value={"susan"}>3 Year</MenuItem>
        </TextField>
      </Box>
      <AppTable
        colSpan={[2, 2, 1, 1, 1, 1, 1]}
        headings={[
          "Rental Name",
          "Property Name",
          "Date",
          "Status",
          "Rent",
          "Fee",
        ]}
        row={[
          [
            "Susan",
            "3 Industrial - A l Julie Russell",
            "10/1/2021 - 12/30/2023",
            <Chip label="Complete" color="success" variant="outlined" />,
            "500$",
            "20$",
          ],
          [
            "Susan",
            "3 Industrial - A l Julie Russell",
            "10/1/2021 - 12/30/2023",
            <Chip label="Pending" color="warning" variant="outlined" />,
            "600$",
            "20$",
          ],
        ]}
      />
    </DashboardLayout>
  );
};

export default PayoutPage;
