import { HistoryOutlined, VisibilityOutlined } from "@mui/icons-material";
import { Typography, Box, Divider } from "@mui/material";
import React from "react";

const BasicDetail = () => {
  return (
    <>
      <Typography
        fontWeight={700}
        fontSize={30}
        variant="h4"
        color="text.primary"
        pb={1}
      >
        Awesome Interior Apartment
      </Typography>
      <Typography fontSize={15} variant="body1" color="text.primary">
        4834 N 10th St, Philadelphia, PA 19141
      </Typography>
      <Box display="flex" gap={1.25} alignItems="center" pt={2}>
        <Box
          display="flex"
          gap={"7px"}
          color="text.primary"
          alignItems={"center"}
        >
          <Box
            width={6}
            height={6}
            borderRadius={2}
            bgcolor="warning.main"
          ></Box>
          <Typography
            fontSize={15}
            variant="body1"
            color={"warning.main"}
            fontWeight={700}
          >
            For rent
          </Typography>
        </Box>
        <Divider orientation="vertical" sx={{ height: 20, color: "#DDDDDD" }} />
        <Box
          display="flex"
          gap={"7px"}
          color="text.primary"
          alignItems={"center"}
        >
          <Box
            width={6}
            height={6}
            borderRadius={2}
            bgcolor="success.main"
          ></Box>
          <Typography
            fontSize={15}
            variant="body1"
            color={"success.main"}
            fontWeight={700}
          >
            For sale
          </Typography>
        </Box>
        <Divider orientation="vertical" sx={{ height: 20, color: "#DDDDDD" }} />
        <Box
          display="flex"
          gap={"7px"}
          color="text.primary"
          alignItems={"center"}
        >
          <HistoryOutlined sx={{ fontSize: 16 }} />
          <Typography fontSize={15} variant="body1">
            1 years ago
          </Typography>
        </Box>
        <Divider orientation="vertical" sx={{ height: 20, color: "#DDDDDD" }} />
        <Box
          display="flex"
          gap={"7px"}
          color="text.primary"
          alignItems={"center"}
        >
          <VisibilityOutlined sx={{ fontSize: 16 }} />
          <Typography fontSize={15} variant="body1">
            8721
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default BasicDetail;
