import { Container, Typography, Box, InputAdornment } from "@mui/material";
import GoogleMapReact from "google-map-react";
import { FC, useState } from "react";
import { FormTextField, FormTextArea, FormSelect } from "..";
import ImagePicker from "../inputs/ImagePicker";
import { useFormContext, useWatch } from "react-hook-form";
import { useAppTheme } from "../../core/useAppTheme";
import { HomeWorkOutlined, PaidOutlined } from "@mui/icons-material";
import { envVariables } from "../../env";
import { FormMultiCheckBox } from "../inputs/FormMultiCheckBox";

export const BasicPropertyDetails = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: 3, py: 10 }}
    >
      <Typography fontSize={28} fontWeight={700}>
        Step #1
      </Typography>
      <FormTextField
        name="listingTitle"
        placeholder="Enter listing title"
        label="Listing Title"
      />
      <FormTextArea
        name="listingDec"
        placeholder="Listing description..."
        label="Listing Description"
        minRows={4}
        maxRows={7}
      />
    </Container>
  );
};

export const PropertyType = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: 3, py: 10 }}
    >
      <Typography fontSize={28} fontWeight={700}>
        Step #2
      </Typography>
      <FormSelect
        name={"propertyType"}
        label={"What type of property?"}
        placeholder={"Property Type"}
        defineOptions={[
          {
            text: "House",
            value: 0,
          },
          {
            text: "Apartment",
            value: 1,
          },
          {
            text: "Hotel",
            value: 2,
          },
          {
            text: "Condo",
            value: 3,
          },
        ]}
      />
      <FormSelect
        name={"ownProperty"}
        label={"Do you own this property?"}
        placeholder={"Yes / No"}
        defineOptions={[
          {
            text: "Yes",
            value: 1,
          },
          {
            text: "No",
            value: 0,
          },
        ]}
      />
      <FormSelect
        name={"tenderSpace"}
        label={"What type of space will tenants have?"}
        placeholder={"Space type"}
        defineOptions={[
          {
            text: "Private Roonm",
            value: 0,
          },
          {
            text: "Entire Room",
            value: 1,
          },
          {
            text: "Shared Room",
            value: 2,
          },
        ]}
      />
    </Container>
  );
};

export const PropertyServiceType = () => {
  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: 5, py: 6 }}>
      <Typography fontSize={28} fontWeight={700}>
        Step #5
      </Typography>
      <FormMultiCheckBox
        name="timeTerm"
        label="How to want to list your property?"
        options={[
          {
            name: "timeTerm.sell",
            label: "Buyer can buy this property?",
          },
          {
            name: "timeTerm.longTerm",
            label:
              "Rentals can use this property for long term (1 Year - 2 Year - 3 Year or more )?",
          },
          {
            name: "timeTerm.midTerm",
            label:
              "Rentals can use this property for mid term (1 Month to 12 Month)?",
          },
          {
            name: "timeTerm.shortTerm",
            label:
              "Rentals can use this property for short term (1Day to 30Day)?",
          },
        ]}
      />
      <FormMultiCheckBox
        name="preferences"
        label="Set preferences for your tenants?"
        options={[
          {
            name: "preferences.teens",
            label: "Suitable for children (2 - 14 years)",
          },
          {
            name: "preferences.babys",
            label: "Suitable for infants (Under 2 years)",
          },
          {
            name: "preferences.pets",
            label: "Suitable for pets",
          },
          {
            name: "preferences.smokes",
            label: "Smoking not allowed",
          },
          {
            name: "preferences.parties",
            label: "Events or parties allowed",
          },
        ]}
      />
    </Container>
  );
};

export const PropertyPrice = () => {
  const { control } = useFormContext();

  const serviceType = useWatch({
    name: "timeTerm",
    control,
  });

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: 3, py: 10 }}
    >
      <Typography fontSize={28} fontWeight={700}>
        Step #6
      </Typography>

      {serviceType?.sell ? (
        <FormTextField
          name="sellPrice"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2 }}>
                <PaidOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            "& input": {
              pl: 1,
            },
          }}
          placeholder="Enter your desire price"
          label="What is the price of this property?"
        />
      ) : (
        <></>
      )}
      {serviceType?.longTerm ? (
        <FormTextField
          name="yearPrice"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2 }}>
                <PaidOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            "& input": {
              pl: 1,
            },
          }}
          placeholder="Enter your desire rent"
          label="What is the rent of this property for one year?"
        />
      ) : (
        <></>
      )}
      {serviceType?.midTerm ? (
        <FormTextField
          name="monthPrice"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2 }}>
                <PaidOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            "& input": {
              pl: 1,
            },
          }}
          placeholder="Enter your desire rent"
          label="What is the rent of this property for one month?"
        />
      ) : (
        <></>
      )}
      {serviceType?.shortTerm ? (
        <FormTextField
          name="dayPrice"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ ml: 2 }}>
                <PaidOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            "& input": {
              pl: 1,
            },
          }}
          placeholder="Enter your desire rent"
          label="What is the rent of this property for one day?"
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export const PropertyDetail = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: 3, py: 10 }}
    >
      <Typography fontSize={28} fontWeight={700}>
        Step #3
      </Typography>
      <FormTextField
        name="squareFeet"
        type="number"
        placeholder="Enter area of property"
        label="How many square feet is the property?"
      />
      <FormSelect
        name="bedrooms"
        placeholder="Enter Bedrooms"
        label="How many bedrooms?"
        defineOptions={[
          {
            text: "1 Bedrooms",
            value: 1,
          },
          {
            text: "2 Bedrooms",
            value: 2,
          },
          {
            text: "3 Bedrooms",
            value: 3,
          },
          {
            text: "4 Bedrooms",
            value: 4,
          },
          {
            text: "5+ Bedrooms",
            value: 5,
          },
        ]}
      />
      <FormSelect
        name="bathrooms"
        placeholder="Enter Bathrooms"
        label="How many bathrooms?"
        defineOptions={[
          {
            text: "1 Bathrooms",
            value: 1,
          },
          {
            text: "2 Bathrooms",
            value: 2,
          },
          {
            text: "3 Bathrooms",
            value: 3,
          },
          {
            text: "4 Bathrooms",
            value: 4,
          },
          {
            text: "5+ Bathrooms",
            value: 5,
          },
        ]}
      />
    </Container>
  );
};

export const PropertyLocation = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: 3, py: 10 }}
    >
      <Typography fontSize={28} fontWeight={700}>
        Step #7
      </Typography>
      <FormTextField
        name="location"
        type="search"
        placeholder="Enter address"
        label="Address"
      />
      <FormTextField
        name="streetAddress"
        placeholder="Enter street address"
        label="Street address"
      />
      <FormTextField
        name="optionalAddress"
        placeholder="Apt, Suite, Bldg. (optional)"
        label="Apt, Suite, Bldg. (optional)"
      />
      <Box display="grid" gap={3} gridTemplateColumns="auto auto">
        <FormTextField name="city" placeholder="Enter city" label="City" />
        <FormTextField
          name="state"
          placeholder="Enter state / province"
          label="State / Province"
        />
        <FormTextField
          name="zipCode"
          placeholder="Enter ZIP code"
          label="ZIP code"
        />
      </Box>
    </Container>
  );
};

export const PropertyMap = () => {
  const [mark, setMark] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        pt: 4,
        height: "calc(100vh - 87px)",
      }}
    >
      <Container>
        <Typography fontSize={28} fontWeight={700}>
          Step #8
        </Typography>
      </Container>
      <div style={{ flex: 1 }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: envVariables.googleMapKey }}
          defaultZoom={11}
          defaultCenter={mark}
        ></GoogleMapReact>
      </div>
    </Box>
  );
};

export const PropertyAmunities = () => {
  const { appColor, mobile } = useAppTheme();
  const { control, setValue } = useFormContext();
  const amunities = useWatch({
    name: "amunities",
    control,
  });

  const amunitiesList = [
    "Towels, bed sheets, soap, and toilet paper",
    "Wifi",
    "Smoke detector",
    "Carbon monoxide detector",
    "Parking",
    "Swimming Pool",
    "Washer/Dryer",
    "Alarm System",
  ];

  const handleClick = (value: string, index: number) => {
    const updatedAmunities = [...amunities];
    if (updatedAmunities.includes(value)) {
      const indexOfValue = updatedAmunities.indexOf(value);
      updatedAmunities.splice(indexOfValue, 1);
    } else {
      updatedAmunities.push(value);
    }
    setValue("amunities", updatedAmunities);
  };

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: 3, py: 10 }}
    >
      <Typography fontSize={28} fontWeight={700}>
        Step #4
      </Typography>
      <Box display="flex" flexWrap="wrap" width="100%" gap={2}>
        {amunitiesList.map((e, key) => {
          const isSelected = amunities.includes(e);
          return (
            <Box
              key={key}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              border="1px solid red"
              borderColor={isSelected ? appColor.sec.l : appColor.other.divider}
              bgcolor={isSelected ? appColor.sec.t : "transparent"}
              height={140}
              borderRadius={2}
              gap={1}
              p={2}
              width={
                mobile ? "calc((100% - 16px) / 2)" : "calc((100% - 32px) / 3)"
              }
              sx={{ cursor: "pointer" }}
              onClick={() => handleClick(e, key)}
            >
              <HomeWorkOutlined sx={{ color: "text.primary", fontSize: 32 }} />
              <Typography variant="body2" textAlign="center">
                {e}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export const PropertyImages: FC<{
  files: File[];
  setFiles: Function;
  onDelete: (index: number) => void;
}> = ({ files, setFiles, onDelete }) => {
  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: 3, py: 6 }}>
      <Typography fontSize={28} fontWeight={700}>
        Step #9
      </Typography>
      <ImagePicker
        files={files}
        setFiles={setFiles}
        onDelete={onDelete}
        label={"Listing Images"}
        name={"listingImage"}
      />
    </Container>
  );
};

export const PropertyRules = () => {
  return (
    <Container sx={{ display: "flex", flexDirection: "column", gap: 4, py: 8 }}>
      <Typography fontSize={28} fontWeight={700}>
        Step #10
      </Typography>

      <Box display="flex" flexDirection="column" gap={0.5}>
        <Typography fontSize={28} fontWeight={700}>
          Your local laws and taxes
        </Typography>
        <Typography fontSize={16} color="text.secondary" fontWeight={600}>
          Take a moment to review your local laws. We want to make sure you’ve
          got everything you need to get off to a great start.
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={1.25}>
        <Typography>
          Please educate yourself about the laws in your jurisdiction before
          listing your space.
        </Typography>

        <Typography>
          Most cities have rules covering homesharing, and the specific codes
          and ordinances can appear in many places (such as zoning, building,
          licensing or tax codes). In most places, you must register, get a
          permit, or obtain a license before you list your property or accept
          guests. You may also be responsible for collecting and remitting
          certain taxes. In some places, short-term rentals could be prohibited
          altogether.
        </Typography>

        <Typography>
          Since you are responsible for your own decision to list or book, you
          should get comfortable with the applicable rules before listing on
          Rentalhome. To get you started, we offer some helpful resources under
          “Your City Laws.”
        </Typography>

        <Typography>
          By accepting our Terms of Service and listing your space, you certify
          that you will follow applicable laws and regulations.
        </Typography>
      </Box>
    </Container>
  );
};
